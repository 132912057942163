<template>
  <div>
    <b-upload
      :value="files"
      size="is-small"
      :multiple="!!isMultiFileUpload"
      :accept="acceptedFormats"
      @input="handleUpdate($event)"
    >
      <span class="file-cta">
        <b-icon
          class="file-icon"
          size="is-small"
          icon="upload"
        />
        <span class="file-label">{{ field.properties.basic.label }}</span>
      </span>
      <span
        v-if="filesToBeUploaded.length"
        class="file-name"
        style="border: none!important;"
      >
        {{ filesToBeUploaded.map(file => file.name).join(',') }}
      </span>
    </b-upload>
    <b-icon 
      v-if="filesToBeUploaded.length"
      icon="close"
      type="is-danger"
      class="clear-upload-btn"
      @click.native="clearFileUploads()"
    />
  </div>
</template>

<script >
import { computed, set } from '@vue/composition-api';
import axios from 'axios';
import { useApplicationStore } from '@/store/applicationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const applicationStore = useApplicationStore();
  const files = computed({
    get() {
      return applicationStore.uploadedFiles[props.field.id] || (isMultiFileUpload.value ? [] : {});
    },
    set(value) {
      if (props.field.id in applicationStore.uploadedFiles) {
        applicationStore.uploadedFiles[props.field.id] = value;
      } else {
        set(applicationStore.uploadedFiles, props.field.id, value);
      }
    }
  });
  const isMultiFileUpload = computed(() => !!props.field.properties.basic?.multiple);
  const filesToBeUploaded = computed(() => {
    if (applicationStore.uploadedFiles[props.field.id]) {
      return isMultiFileUpload.value ? (files.value || []).map(file => file) : [files.value];
    }
    return [];
  });
  const acceptedFormats = computed(() => {
    const validationProperties = props.field.properties.validation;
    return !validationProperties.allowAllFormats && validationProperties.allowed?.length ? validationProperties.allowed.join(',') : validationProperties.isCustomFormat ? validationProperties.customFormat : '';
  });
  const handleUpdate = uploadedFile => {
    files.value = uploadedFile;
    upload();
    emit('update', {
      value: filesToBeUploaded.value.map(file => file.name)
    });
  };
  const upload = () => {
    const formData = new FormData();
    filesToBeUploaded.value.forEach(file => {
      formData.append(file.name, file);
    });

    // ignore replacing with services for now
    axios.post(process.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // since upload API clears older uploads, no need to attach current field in deleted uploads map
    if (props.field.id in applicationStore.deletedUploads) {
      applicationStore.deletedUploads[props.field.id] = false; // triggers update since due to vue 2 reactivity, it can't detect property removal from objects
      delete applicationStore.deletedUploads[props.field.id];
    }
  };
  const clearFileUploads = () => {
    if (props.field.id in applicationStore.uploadedFiles) {
      applicationStore.uploadedFiles[props.field.id] = {};
      delete applicationStore.uploadedFiles[props.field.id];
    }
    if (!(props.field.id in applicationStore.deletedUploads)) {
      set(applicationStore.deletedUploads, props.field.id, true);
    }
    emit('update', {
      value: ['']
    });
  };
  return {
    files,
    isMultiFileUpload,
    filesToBeUploaded,
    acceptedFormats,
    handleUpdate,
    clearFileUploads
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.clear-upload-btn {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
</style>
